import React from "react";

const Orders = () => {
  return (
    <div>
      <h1>Orders Page</h1>
    </div>
  );
}

export default Orders; 